import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import TensCam from './pages/tensorflow/cam/cam';
import Vid from './pages/vid_css/vid';

function App() {
  return (
    <Router>
      <div>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/d3">D3</Link>
            </li>
            <li>
              <Link to="/vid">Vid</Link>
            </li>
            <li>
              Tensorflow
              <ul>
                <li><Link to="/tensorflow_cam">Camera</Link></li>
              </ul>
            </li>
          </ul>
        </nav>

        <Switch>
          <Route path="/tensorflow_cam">
            <TensCam />
          </Route>
          <Route path="/d3">
          <p>D3</p>
          </Route>
          <Route path="/vid">
          <Vid />
          </Route>
          <Route path="/">
          <p>Home</p>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}



export default App;
