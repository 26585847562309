import React, { useEffect, useState, useRef } from "react";
import './vid.css';
import VID_SNOW from '../../media/vid/snow.mp4';

const Vid = () => {

    return(
        <div>

            <section className="showcase">

                <header>

                    <h2 className="logo">Travel</h2>
                    <div className="toggle">

                    </div>
                </header>
                
                <video src={VID_SNOW} muted loop autoPlay ></video>

                <div className="overlay"></div>

        <div className="text">
            <h2>Never stop</h2>
            <h3>Exploring the world</h3>
            <p>Tekaj ajsdj ajasdjka</p>
            <a href="#">Explore</a>
        </div>


        <div className="menu">
            <li><a href="#">Test</a></li>
            <li><a href="#">Test</a></li>
            <li><a href="#">Test</a></li>
            <li><a href="#">Test</a></li>
            <li><a href="#">Test</a></li>

        </div>

            </section>


        </div>
    );
}

export default Vid;